import { Override } from "framer"

export const CardDesktop: Override = () => {
    return {
        style: { width: "25vw" },
    }
}

export const CardPhone: Override = () => {
    return {
        style: { width: "75vw" },
    }
}
